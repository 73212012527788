import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from "react";

interface IZoomSettingContext {
  zoomSettingMap: { [key: string]: boolean };
  setZoomSetting: (id: string, zoom: boolean) => void;
}

const ZoomSettingContext = createContext<IZoomSettingContext | undefined>(
  undefined,
);

export const useZoomSettingContext = () => {
  const context = useContext(ZoomSettingContext);
  if (!context) {
    throw new Error(
      "useZoomSettingContext must be used within a ZoomSettingProvider",
    );
  }
  return context;
};

export const ZoomSettingProvider = ({ children }: { children: ReactNode }) => {
  const [zoomSettingMap, setZoomSettingMap] = useState<{
    [key: string]: boolean;
  }>({});

  const setZoomSetting = useCallback((tableId: string, value: boolean) => {
    setZoomSettingMap((prev) => ({
      ...prev,
      [tableId]: value,
    }));
  }, []);

  return (
    <ZoomSettingContext.Provider value={{ zoomSettingMap, setZoomSetting }}>
      {children}
    </ZoomSettingContext.Provider>
  );
};
