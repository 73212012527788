import Image from "next/image";
import preloading from "@/assets/preload-logo.png";

export default function Loading() {
  return (
    <div className="fixed inset-0 z-[99] flex items-center justify-center bg-black">
      <div className="w-50 h-50 relative">
        {[...Array(10)].map((_, i) => (
          <div
            key={i}
            className={`absolute left-1/2 top-1/2 animate-rotate rounded-full border border-transparent border-l-gray-400 border-r-gray-400 p-[75px]`}
            style={{
              width: `${200 - i * 20}px`,
              height: `${200 - i * 20}px`,
              animationDuration: `${i + 1}s`,
            }}
          ></div>
        ))}
        <div>
          <Image src={preloading} height={100} width={100} alt="" />
        </div>
      </div>
    </div>
  );
}
