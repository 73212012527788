import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  useEffect,
} from "react";
import { IBetRecord } from "../hooks/useBetting";
import { useReactiveVar } from "@apollo/client";
import { selectedTablesVar } from "../utils/apollo";

type IListTotalBets = {
  tableId: string;
  gameCode: string;
  currentBet: IBetRecord;
};

type ListBetsContextType = {
  listTotalBets: IListTotalBets[];
  updateListBets: (
    tableId: string,
    gameCode: string,
    currentBet: IBetRecord,
  ) => void;
  getTotalBetById: (tableId: string, gameCode: string) => IBetRecord;
  getSumTotalBetById: (tableId: string, gameCode: string) => number;
};

// Create Context
const ListBetsContext = createContext<ListBetsContextType | undefined>(
  undefined,
);

// Custom hook to use the context
export const useListBets = () => {
  const context = useContext(ListBetsContext);
  if (!context) {
    throw new Error("useListBets must be used within a ListBetsProvider");
  }
  return context;
};

// Provider component
export const ListBetsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const activeTables = useReactiveVar(selectedTablesVar);
  const [listTotalBets, setListTotalBets] = useState<IListTotalBets[]>([]);

  // Function to get the sum  of currentBet  based on tableId and gameCode
  const getSumTotalBetById = useCallback(
    (tableId: string, gameCode: string) => {
      const data = listTotalBets.find(
        (bet) => bet.tableId === tableId && bet.gameCode === gameCode,
      );

      const sum = Object.values(data?.currentBet || {}).reduce(
        (acc, val) => acc + val,
        0,
      );
      return sum || 0;
    },
    [listTotalBets],
  );

  // Function to get data based on tableId and gameCode
  const getTotalBetById = (tableId: string, gameCode: string) => {
    const data = listTotalBets.find(
      (bet) => bet.tableId === tableId && bet.gameCode === gameCode,
    );
    return (
      data?.currentBet || {
        P: 0,
        B: 0,
        T: 0,
        PP: 0,
        BP: 0,
        S6: 0,
        FP: 0,
        EP: 0,
        L6: 0,
        PB: 0,
        BB: 0,
        BIG: 0,
        SMALL: 0,
      }
    );
  };

  // Function to update or add new bets
  const updateListBets = useCallback(
    (tableId: string, gameCode: string, currentBet: IBetRecord) => {
      setListTotalBets((prevListBets) => {
        const existingBetIndex = prevListBets.findIndex(
          (bet) => bet.tableId === tableId,
        );

        if (existingBetIndex !== -1) {
          // Check if the gameCode has changed
          if (prevListBets[existingBetIndex].gameCode !== gameCode) {
            const updatedBet = {
              ...prevListBets[existingBetIndex],
              gameCode,
              currentBet,
            };
            const updatedListBets = [...prevListBets];
            updatedListBets[existingBetIndex] = updatedBet;
            return updatedListBets;
          }
          return prevListBets; // No changes needed
        }

        // Add a new bet if tableId doesn't exist
        return [
          ...prevListBets,
          {
            tableId,
            gameCode,
            currentBet,
          },
        ];
      });
    },
    [],
  );

  useEffect(() => {
    setListTotalBets((prevListBets) => {
      const updatedListBets = prevListBets.filter((bet) =>
        activeTables.some((table) => table.tableId === bet.tableId),
      );
      return updatedListBets;
    });
  }, [activeTables]);

  return (
    <ListBetsContext.Provider
      value={{
        listTotalBets,
        updateListBets,
        getTotalBetById,
        getSumTotalBetById,
      }}
    >
      {children}
    </ListBetsContext.Provider>
  );
};
