import { createContext, useContext, useState, ReactNode } from "react";

type PredictState = "P" | "B" | null;

interface PredictContextProps {
  predictStateMap: { [key: string]: PredictState }; // Map of each child's predictState
  setPredictState: (id: string, state: PredictState) => void;
}

const PredictContext = createContext<PredictContextProps | undefined>(
  undefined,
);

export const usePredictContext = () => {
  const context = useContext(PredictContext);
  if (!context) {
    throw new Error("usePredictContext must be used within a PredictProvider");
  }
  return context;
};

export const PredictMProvider = ({ children }: { children: ReactNode }) => {
  const [predictStateMap, setPredictStateMap] = useState<{
    [key: string]: PredictState;
  }>({});

  const setPredictState = (id: string, state: PredictState) => {
    setPredictStateMap((prev) => ({ ...prev, [id]: state }));
  };

  return (
    <PredictContext.Provider
      value={{
        predictStateMap: new Proxy(predictStateMap, {
          get: (target, key: string) => target[key] ?? null,
        }),
        setPredictState,
      }}
    >
      {children}
    </PredictContext.Provider>
  );
};
