import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

export interface IPhoneBet {
  user_id: string;
  game_round: string;
  timestamp: number;
  balance_before: number;
  P: number;
  B: number;
  T: number;
  BP: number;
  PP: number;
  balance_after: number;
}

export interface IPhoneBetsValue {
  B: number;
  P: number;
  T: number;
  BP: number;
  PP: number;
}

interface IPhoneBetContext {
  phoneBet: IPhoneBet | undefined;
  setPhoneBet: (state: IPhoneBet | undefined) => void;
  phoneBets: IPhoneBetsValue;
  setPhoneBets: (state: IPhoneBetsValue) => void;
  totalPhoneBets: number;
  setTotalPhoneBets: (state: number) => void;
}

const PhoneBetContext = createContext<IPhoneBetContext | undefined>(undefined);

const usePhoneBetContext = () => {
  const context = useContext(PhoneBetContext);
  if (!context) {
    throw new Error(
      "usePhoneBetContext must be used within a PhoneBetProvider",
    );
  }
  return context;
};

const PhoneBetProvider = ({ children }: { children: ReactNode }) => {
  const [phoneBet, setPhoneBet] = useState<IPhoneBet | undefined>(undefined);
  const [phoneBets, setPhoneBets] = useState<IPhoneBetsValue>({
    B: 0,
    P: 0,
    T: 0,
    BP: 0,
    PP: 0,
  });
  const [totalPhoneBets, setTotalPhoneBets] = useState(0);

  useEffect(() => {
    if (phoneBet) {
      const nextPhoneBets = {
        B: phoneBet.B,
        P: phoneBet.P,
        T: phoneBet.T,
        BP: phoneBet.BP,
        PP: phoneBet.PP,
      };
      setPhoneBets((prevPhoneBets) => {
        return {
          B: nextPhoneBets.B !== 0 ? nextPhoneBets.B : prevPhoneBets.B,
          P: nextPhoneBets.P !== 0 ? nextPhoneBets.P : prevPhoneBets.P,
          BP: nextPhoneBets.BP !== 0 ? nextPhoneBets.BP : prevPhoneBets.BP,
          PP: nextPhoneBets.PP !== 0 ? nextPhoneBets.PP : prevPhoneBets.PP,
          T: nextPhoneBets.T !== 0 ? nextPhoneBets.T : prevPhoneBets.T,
        };
      });
    }
  }, [phoneBet]);

  useEffect(() => {
    if (phoneBets) {
      const sum = Object.values(phoneBets).reduce(
        (acc, value) => acc + value,
        0,
      );
      setTotalPhoneBets(sum);
    }
  }, [phoneBets]);

  return (
    <PhoneBetContext.Provider
      value={{
        phoneBet,
        setPhoneBet,
        phoneBets,
        totalPhoneBets,
        setPhoneBets,
        setTotalPhoneBets,
      }}
    >
      {children}
    </PhoneBetContext.Provider>
  );
};

export { usePhoneBetContext, PhoneBetProvider };
