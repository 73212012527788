import { createContext, ReactNode, useContext, useState } from "react";

interface IVideoQualityContext {
  videoQualityMap: { [key: string]: string };
  setVideoQuality: (id: string, quality: string) => void;
}

const VideoQualityContext = createContext<IVideoQualityContext | undefined>(
  undefined,
);

const useVideoQualityContext = () => {
  const context = useContext(VideoQualityContext);
  if (!context) {
    throw new Error(
      "useVideoQualityContext must be used within a VideoQualityProvider",
    );
  }
  return context;
};

const VideoQualityProvider = ({ children }: { children: ReactNode }) => {
  const [videoQualityMap, setVideoQualityMap] = useState<{
    [key: string]: string;
  }>({});

  const setVideoQuality = (id: string, quality: string) => {
    setVideoQualityMap((prev) => ({ ...prev, [id]: quality }));
  };

  return (
    <VideoQualityContext.Provider
      value={{
        videoQualityMap: new Proxy(videoQualityMap, {
          get: (target, key: string) => target[key] ?? "A",
        }),
        setVideoQuality,
      }}
    >
      {children}
    </VideoQualityContext.Provider>
  );
};

export { useVideoQualityContext, VideoQualityProvider };
