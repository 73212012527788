import { createContext, ReactNode, useContext, useState } from "react";

export interface IPhoneAvatar {
  avatar_id: string;
  avatar_online: boolean;
  avatar_table_id: string;
  avatar_table_code: string;
}

interface IPhoneAvatarContext {
  phoneAvatar: { [key: string]: IPhoneAvatar };
  setPhoneAvatar: (tableId: string, phoneAvatar: IPhoneAvatar) => void;
}

const PhoneAvatarContext = createContext<IPhoneAvatarContext | undefined>(
  undefined,
);

const usePhoneAvatarContext = () => {
  const context = useContext(PhoneAvatarContext);
  if (!context) {
    throw new Error(
      "usePhoneAvatarContext must be used within a PhoneAvatarProvider",
    );
  }
  return context;
};

const PhoneAvatarProvider = ({ children }: { children: ReactNode }) => {
  const [phoneAvatar, setPhoneAvatarMap] = useState<{
    [key: string]: IPhoneAvatar;
  }>({});

  const setPhoneAvatar = (tableId: string, phoneAvatar: IPhoneAvatar) => {
    setPhoneAvatarMap({ [tableId]: phoneAvatar });
  };

  return (
    <PhoneAvatarContext.Provider value={{ phoneAvatar, setPhoneAvatar }}>
      {children}
    </PhoneAvatarContext.Provider>
  );
};

export { usePhoneAvatarContext, PhoneAvatarProvider };
