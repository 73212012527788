import { createContext, ReactNode, useContext, useState } from "react";

type LoadingContext = {
  isLoadingMap: { [key: string]: boolean };
  setIsLoading: (id: string, state: boolean) => void;
};

const LoadingContext = createContext<LoadingContext | undefined>(undefined);

export const LoadingProvider = ({ children }: { children: ReactNode }) => {
  const [isLoadingMap, setIsLoadingMap] = useState<{
    [key: string]: boolean;
  }>({});

  const setIsLoading = (id: string, state: boolean) => {
    setIsLoadingMap((prev) => ({ ...prev, [id]: state }));
  };

  return (
    <LoadingContext.Provider
      value={{
        isLoadingMap: new Proxy(isLoadingMap, {
          get: (target, key: string) => target[key] ?? true,
        }),
        setIsLoading,
      }}
    >
      {children}
    </LoadingContext.Provider>
  );
};

export const useLoadingContext = () => {
  const context = useContext(LoadingContext);
  if (!context) {
    throw new Error("useLoadingContext must be used within a LoadingProvider");
  }
  return context;
};
