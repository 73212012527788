import { ReactNode } from "react";
import { TableTypeMProvider } from "./TableType";
import { PredictMProvider } from "./Predict";
import { RoadMetadataMProvider } from "./RoadMetadata";
import { TableInfoProvider } from "./TableInfo";
import { VideoQualityProvider } from "./VideoQuality";
import { LoadingProvider } from "./Loading";
import { FocusTableSwitchProvider } from "./FocusTableSwitch";
import { ZoomSettingProvider } from "./ZoomSetting";
import { FixedBettingBoardSettingProvider } from "./FixedBettingBoard";
function MultibetProvider({ children }: { children: ReactNode }) {
  return (
    <LoadingProvider>
      <TableTypeMProvider>
        <FocusTableSwitchProvider>
          <PredictMProvider>
            <RoadMetadataMProvider>
              <TableInfoProvider>
                <VideoQualityProvider>
                  <ZoomSettingProvider>
                    <FixedBettingBoardSettingProvider>
                      {children}
                    </FixedBettingBoardSettingProvider>
                  </ZoomSettingProvider>
                </VideoQualityProvider>
              </TableInfoProvider>
            </RoadMetadataMProvider>
          </PredictMProvider>
        </FocusTableSwitchProvider>
      </TableTypeMProvider>
    </LoadingProvider>
  );
}

export default MultibetProvider;
