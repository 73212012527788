import { useUserSettingsAlert } from "@/common/context/UserSettingsAlertProvider";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { CheckNotifIcon } from "../multibet/svg-Images/flagIcons";

interface ISystemModalProps {
  onCloseModal: React.DispatchWithoutAction;
}

export const UserSettingsModal = (props: ISystemModalProps) => {
  const { t } = useTranslation();

  const { hideModal } = useUserSettingsAlert();

  useEffect(() => {
    const timer = setTimeout(() => {
      hideModal();
    }, 1000);

    // Cleanup the timeout when the component is unmounted
    return () => clearTimeout(timer);
  }, [hideModal]);

  return (
    <div className="pointer-events-none fixed inset-0 z-[999] grid h-screen w-screen place-items-center pl-4 pr-6 text-white">
      <div className="flex w-full max-w-[500px] items-center justify-center gap-x-[3%] rounded-lg border border-gray-400 bg-[#0D0D0D] p-[0.5%] text-center [container-type:inline-size]">
        <div className="w-[5%]">
          <CheckNotifIcon />
        </div>
        <div className="text-[4cqw]">
          {/* <p className="font-bold">{t(props.message)}</p>
          <hr className="mx-auto mt-2 w-[80%]" />
          <p>{props.code}</p>
          <br />
          <button onClick={props.onCloseModal}>{t("Close")}</button> */}
          {t("the_game_settings_have_been_saved")}
        </div>
      </div>
    </div>
  );
};
