import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from "react";

interface IFixedBettingBoardSettingContext {
  fixedBettingBoardSettingMap: { [key: string]: boolean };
  setFixedBettingBoardSetting: (id: string, fixedBet: boolean) => void;
}

const FixedBettingBoardSettingContext = createContext<
  IFixedBettingBoardSettingContext | undefined
>(undefined);

export const useFixedBettingBoardSettingContext = () => {
  const context = useContext(FixedBettingBoardSettingContext);
  if (!context) {
    throw new Error(
      "useFixedBettingBoardSettingContext must be used within a FixedBettingBoardSettingProvider",
    );
  }
  return context;
};

export const FixedBettingBoardSettingProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [fixedBettingBoardSettingMap, setFixedBettingBoardSettingMap] =
    useState<{
      [key: string]: boolean;
    }>({});

  const setFixedBettingBoardSetting = useCallback(
    (tableId: string, value: boolean) => {
      setFixedBettingBoardSettingMap((prev) => ({
        ...prev,
        [tableId]: value,
      }));
    },
    [],
  );

  return (
    <FixedBettingBoardSettingContext.Provider
      value={{ fixedBettingBoardSettingMap, setFixedBettingBoardSetting }}
    >
      {children}
    </FixedBettingBoardSettingContext.Provider>
  );
};
