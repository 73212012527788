import { createContext, ReactNode, useContext, useState } from "react";

export type RoundResult = {
  table_id: string;
  winner: number;
  pcards: string;
  bcards: string;
  pscore: number;
  bscore: number;
  game_number: string;
};
interface IRoundResultContext {
  roundResultMap: { [key: string]: RoundResult };
  setRoundResult: (tableId: string, state: RoundResult) => void;
}

const RoundResultContext = createContext<IRoundResultContext | undefined>(
  undefined,
);

const useRoundResultContext = () => {
  const context = useContext(RoundResultContext);
  if (!context) {
    throw new Error(
      "useRoundResultContext must be used within a RoundResultProvider",
    );
  }
  return context;
};

const RoundResultProvider = ({ children }: { children: ReactNode }) => {
  const [roundResultMap, setRoundResultMap] = useState<{
    [key: string]: RoundResult;
  }>({});

  const setRoundResult = (tableId: string, state: RoundResult) => {
    setRoundResultMap((prev) => ({ ...prev, [tableId]: state }));
  };

  return (
    <RoundResultContext.Provider value={{ roundResultMap, setRoundResult }}>
      {children}
    </RoundResultContext.Provider>
  );
};

export { useRoundResultContext, RoundResultProvider };
