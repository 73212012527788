import { createContext, ReactNode, useContext, useState } from "react";

export interface ITableInfo {
  tableId: string;
  tableType: string;
  tableCode: string;
}

interface ITableInfoContext {
  tableInfoMap: { [key: string]: ITableInfo }; // Map of tableInfo for each child
  setTableInfo: (tableId: string, tableInfo: ITableInfo) => void;
}

const TableInfoContext = createContext<ITableInfoContext | undefined>(
  undefined,
);

const useTableInfoContext = () => {
  const context = useContext(TableInfoContext);
  if (!context) {
    throw new Error(
      "useTableInfoContext must be used within a TableInfoProvider",
    );
  }
  return context;
};

const TableInfoProvider = ({ children }: { children: ReactNode }) => {
  const [tableInfoMap, setTableInfoMap] = useState<{
    [key: string]: ITableInfo;
  }>({});

  const setTableInfo = (tableId: string, tableInfo: ITableInfo) => {
    setTableInfoMap((prev) => ({ ...prev, [tableId]: tableInfo }));
  };

  return (
    <TableInfoContext.Provider value={{ tableInfoMap, setTableInfo }}>
      {children}
    </TableInfoContext.Provider>
  );
};

export { useTableInfoContext, TableInfoProvider };
