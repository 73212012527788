"use client";

import React, { createContext, useContext, ReactNode } from "react";

type DeviceType = "mobile" | "desktop";
type OS = "Android" | "iOS" | "Windows" | "MacOS" | "Linux" | "unknown";

interface DeviceContextType {
  deviceType: DeviceType;
  os: OS;
}

const DeviceContext = createContext<DeviceContextType | null>(null);

interface DeviceProviderProps {
  children: ReactNode;
  deviceType: DeviceType;
  os: OS;
}

export function DeviceProvider({
  children,
  deviceType,
  os,
}: DeviceProviderProps) {
  return (
    <DeviceContext.Provider value={{ deviceType, os }}>
      {children}
    </DeviceContext.Provider>
  );
}

export function useDevice(): DeviceContextType {
  const context = useContext(DeviceContext);
  if (!context) {
    throw new Error("useDevice must be used within a DeviceProvider");
  }
  return context;
}
