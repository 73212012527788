import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import { useRouter } from "next/navigation";
import { UserSettingsModal } from "@/components/common/userSettings_modal";

interface UserSettingsAlertContext {
  isSystemOpen: boolean;
  showModal: () => void;
  hideModal: () => void;
}

const UserSettingsAlertContext = createContext<
  UserSettingsAlertContext | undefined
>(undefined);

export function UserSettingsAlertProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [isSystemOpen, setIsSystemOpen] = useState(false);

  const showModal = () => {
    setIsSystemOpen(true);
  };

  const hideModal = () => {
    setIsSystemOpen(false);
  };

  return (
    <UserSettingsAlertContext.Provider
      value={{ isSystemOpen, showModal, hideModal }}
    >
      {children}
      {isSystemOpen && <UserSettingsModal onCloseModal={hideModal} />}
    </UserSettingsAlertContext.Provider>
  );
}

export function useUserSettingsAlert() {
  const context = useContext(UserSettingsAlertContext);
  if (!context) {
    throw new Error(
      "useUserSettingsAlert must be used within a UserSettingsAlertProvider",
    );
  }
  return context;
}
