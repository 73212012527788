import { createContext, ReactNode, useContext, useMemo, useState } from "react";

interface IRoadMetadataContext {
  roadMetadataMap: { [key: string]: string };
  setRoadMetadata: (id: string, metadata: string) => void;
}

const RoadMetadataContext = createContext<IRoadMetadataContext | undefined>(
  undefined,
);

const useRoadMetadataContext = () => {
  const context = useContext(RoadMetadataContext);
  if (!context) {
    throw new Error(
      "useRoadMetadataContext must be used within a RoadMetadataProvider",
    );
  }
  return context;
};

const RoadMetadataMProvider = ({ children }: { children: ReactNode }) => {
  const [roadMetadataMap, setRoadMetadataMap] = useState<{
    [key: string]: string;
  }>({});

  const setRoadMetadata = (id: string, metadata: string) => {
    setRoadMetadataMap((prev) => ({ ...prev, [id]: metadata }));
  };

  const contextValue = useMemo(
    () => ({
      roadMetadataMap,
      setRoadMetadata,
    }),
    [roadMetadataMap],
  );

  return (
    <RoadMetadataContext.Provider value={contextValue}>
      {children}
    </RoadMetadataContext.Provider>
  );
};

export { useRoadMetadataContext, RoadMetadataMProvider };
