import { createContext, ReactNode, useContext, useState } from "react";

interface IUserTypeContext {
  volume: number;
  setVolume: (state: number) => void;
}

const VolumeContext = createContext<IUserTypeContext | undefined>(undefined);

const useVolumeContext = () => {
  const context = useContext(VolumeContext);
  if (!context) {
    throw new Error(
      "useUserTypeContext must be used within a UserTypeProvider",
    );
  }
  return context;
};

const VolumeProvider = ({ children }: { children: ReactNode }) => {
  const [volume, setVolume] = useState<number>(1);

  return (
    <VolumeContext.Provider value={{ volume, setVolume }}>
      {children}
    </VolumeContext.Provider>
  );
};

export { useVolumeContext, VolumeProvider };
