import { createContext, ReactNode, useContext, useState } from "react";

export type FocusTable = {
  tableId: string;
};

interface IFocusTableSwitchContext {
  focusedTable: FocusTable | null;
  setFocusedTable: (state: FocusTable | null) => void;
}

const FocusTableSwitchContext = createContext<
  IFocusTableSwitchContext | undefined
>(undefined);

export const useFocusTableSwitchContext = () => {
  const context = useContext(FocusTableSwitchContext);
  if (!context) {
    throw new Error(
      "useFocusTableSwitchContext must be used within a FocusTableSwitchProvider",
    );
  }
  return context;
};

export const FocusTableSwitchProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [focusedTable, setFocusedTable] = useState<FocusTable | null>(null);

  return (
    <FocusTableSwitchContext.Provider value={{ focusedTable, setFocusedTable }}>
      {children}
    </FocusTableSwitchContext.Provider>
  );
};
