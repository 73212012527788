import { createContext, ReactNode, useContext, useState } from "react";

interface ITableTypeContext {
  tableTypes: { [key: string]: string };
  setTableType: (id: string, tableType: string) => void;
}

const TableTypeContext = createContext<ITableTypeContext | undefined>(
  undefined,
);

const useTableTypeContext = () => {
  const context = useContext(TableTypeContext);
  if (!context) {
    throw new Error(
      "useTableTypeContext must be used within a TableTypeProvider",
    );
  }
  return context;
};

const TableTypeMProvider = ({ children }: { children: ReactNode }) => {
  const [tableTypes, setTableTypes] = useState<{ [key: string]: string }>({});

  const setTableType = (id: string, tableType: string) => {
    setTableTypes((prev) => ({ ...prev, [id]: tableType }));
  };

  return (
    <TableTypeContext.Provider value={{ tableTypes, setTableType }}>
      {children}
    </TableTypeContext.Provider>
  );
};

export { useTableTypeContext, TableTypeMProvider };
